<template>
    <section>
        <div class="row mx-0 mt-3 bg-white br-t-8">
            <div class="col-12 py-3 d-middle">
                <div class="col-auto text-general f-18 f-500">
                    Liquidaciones
                </div>
                <div class="col-3 ml-auto">
                    <el-input v-model="buscar" prefix-icon="icon-search" placeholder="Buscar liquidado" size="small" class="w-100 br-20" />
                </div>
            </div>
            <div class="col-12 px-0">
                <el-table
                :data="pedidos.filter(data => !buscar || JSON.stringify(data).toLowerCase().includes(buscar.toLowerCase()))"
                style="width: 100%;"
                height="calc(100vh - 478px)"
                header-row-class-name="text-general f-500 f-17 text-center"
                @row-click="pedidoCliente"
                >
                    <el-table-column
                    width="50"
                    >
                        <template slot-scope="scope">
                            <div class="row mx-0 justify-center">
                                <el-tooltip v-if="scope.row.estado === 5 || scope.row.estado === 6" content="Desvinculado" placement="bottom" effect="light">
                                    <i class="icon-account-remove-outline text-general-red f-18" />
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="id_cliente"
                    label="Código."
                    class-name="text-center"
                    min-width="150"
                    />
                    <el-table-column
                    prop="cliente"
                    label="Nombre"
                    width="180"
                    />
                    <el-table-column
                    min-width="150"
                    class-name="text-center"
                    label="Documento"
                    >
                        <template slot-scope="scope">
                            <template v-if="scope.row.en_convenio != 0">
                                {{ scope.row.documento }}
                            </template>
                            <template v-else>
                                -
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                    min-width="100"
                    class-name="text-center"
                    label="Deuda"
                    >
                        <template slot-scope="scope">
                            {{ separadorNumeroDecimales(scope.row.deuda) }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="pedidos"
                    class-name="text-center"
                    label="Pedidos"
                    min-width="100"
                    />
                    <el-table-column
                    min-width="150"
                    class-name="text-center"
                    label="Cupo"
                    >
                        <template slot-scope="scope">
                            <template v-if="scope.row.en_convenio != 0">
                                {{ scope.row.cupo === 0 ? 'Ilimitado' : separadorNumeroDecimales(scope.row.cupo) }}
                            </template>
                            <template v-else>
                                -
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                    min-width="150"
                    class-name="text-center"
                    label="Antigüedad"
                    >
                        <template slot-scope="scope">
                            <template v-if="scope.row.en_convenio != 0">
                                {{ scope.row.fecha_vinculacion != null ? fechaAntiguedad(scope.row.fecha_vinculacion) : '-' }}
                            </template>
                            <template v-else>
                                -
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                    min-width="200"
                    class-name="text-center"
                    label="Fecha de registro"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.fecha_registro | helper-fecha('DD MMM YYYY') }}
                        </template>
                    </el-table-column>
                    />
                    <el-table-column
                    min-width="80"
                    class-name="text-center"
                    label="Fecha de liquidacion"
                    >
                        <template slot="header">
                            <i class="icon-truck-check f-20 text-green" />
                        </template>
                        <template slot-scope="scope">
                            {{ scope.row.porcentaje_entrega }}%
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <modal-pedidos-cliente ref="pedidosCliente" />
    </section>
</template>
<script>
import moment from 'moment'

export default {
    components: {
        modalPedidosCliente: () => import('../partials/modalPedidosCliente.vue')
    },
    props:{
        pedidos: {
            type: Array,
            default: () => false
        },
        fechaLiquidacion: {
            type: String,
            default: ''
        },
    },
    data(){
        return {
            buscar: '',
        }
    },
    computed: {
        id_liquidacion(){
            return this.$route.params.id_liquidacion
        },
    },
    methods: {
        fechaAntiguedad(fecha){
            if(fecha === null || fecha === '')return '-';
            return moment(fecha).startOf('day').fromNow();
        },
        pedidoCliente(row){
            this.$refs.pedidosCliente.toggle(this.id_liquidacion, row.id_cliente, this.fechaLiquidacion);
        },
        formatoCupo(value){
            return parseInt(value) === 0 ? 'Ilimitado' : this.agregarSeparadoresNumero(value);
        },
        formatoDeuda(value){
            return this.agregarSeparadoresNumero(value);
        },
        formatoEntrega(value){
            return value+"%";
        },
        formatoFechaRegistro(value){
            return moment(value).format("DD MMM YYYY");
        },
    }
}
</script>